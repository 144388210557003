import {
	Map,
} from 'immutable';

import AcceptAccessToClientModal from '../../../components/logic/accountAccess/modals/AcceptAccessToClientModal';
import AcceptEnrichmentApiTermsOfUseModal from '~/components/app/AcceptEnrichmentApiTermsOfUseModal';
import AcceptReportingApiTermsOfUseModal from '~/components/app/AcceptReportingApiTermsOfUseModal';
import AddMemberModal from '../../../components/logic/addMemberScreen/Modal';
import AddWebsiteModal from '../../../components/logic/addWebsiteScreen/Modal';
import AffectedLinksScreen from '../../../components/logic/screens/AffectedLinksScreen';
import AffectedPagesPanel from '../../../components/views/authenticated/AffectedPagesPanel';
import AlertPagesScreen from '../../../components/logic/screens/AlertPagesScreen';
import AlertsConfigurationScreen from '../../../components/logic/screens/AlertsConfigurationScreen';
import AlertsScreen from '../../../components/logic/screens/AlertsScreen';
import ChangePasswordModal from '~/components/app/ChangePasswordModal';
import ChangeUserRoleModal from '~/components/app/ChangeUserRoleModal';
import ConnectAccountModal from '../../../components/logic/accountAccess/modals/connect/ConnectAccountModal';
import ConnectToAgencyModal from '../../../components/logic/accountAccess/modals/ConnectToAgencyModal';
import ContentRelationsPanel from '../../../components/views/authenticated/ContentRelationsPanel';
import CreateAlertDefinitionsModal from '../../../components/logic/alertsConfiguration/modals/create/CreateAlertDefinitionsModal';
import CreateEnrichmentFieldModal from '~/components/app/CreateEnrichmentFieldModal';
import CustomElementModal from '../../../components/logic/customElements/Modal';
import DatePickerPanel, {
	SCOPE_AFFECTED_PAGES as DATE_PICKER_SCOPE_AFFECTED_PAGES,
	SCOPE_ISSUES as DATE_PICKER_SCOPE_ISSUES,
	SCOPE_PAGES as DATE_PICKER_SCOPE_PAGES,
	SCOPE_SINGLE_ISSUE_AFFECTED_PAGES as DATE_PICKER_SCOPE_SINGLE_ISSUE_AFFECTED_PAGES,
} from '../../../components/views/authenticated/DatePickerPanel';
import DashboardScreen from '~/components/app/Dashboard/DashboardScreen';
import DisableTwoFactorAuthenticationModal from '~/components/app/DisableTwoFactorAuthenticationModal';
import EditAlertDefinitionModal from '~/components/app/EditAlertDefinitionModal';
import EditAlertDefinitionsMessagingAppModal from '~/components/app/EditAlertDefinitionsMessagingAppModal';
import EditAlertDefinitionsRecipientsModal from '~/components/app/EditAlertDefinitionsRecipientsModal';
import EditAlertDefinitionsScopeModal from '~/components/app/EditAlertDefinitionsScopeModal';
import EditAlertDefinitionsSensitivityModal from '~/components/app/EditAlertDefinitionsSensitivityModal';
import EmptyAppScreen from '../../../components/logic/screens/EmptyAppScreen';
import EntryPage from '../../../components/views/authenticated/EntryPage';
import Error404 from '../../../components/views/common/Error404';
import ExclusionsModal from '../../../components/atoms/modals/ExclusionsModal';
import ExportPanel from '../../../components/views/authenticated/ExportPanel';
import GeneralAdminScreen from '~/components/app/GeneralAdminScreen';
import GrantAccessToAgencyModal from '../../../components/logic/accountAccess/modals/GrantAccessToAgencyModal';
import HomePage from '../../../components/views/authenticated/HomePage';
import ImportSegmentsModal from '../../../components/logic/segmentManagement/ImportSegmentsModal';
import IssueCategoryConfigurationModal from '~/components/app/IssueCategoryConfigurationModal';
import IssuesOverviewFilterPanel from '../../../components/views/authenticated/IssuesOverviewFilterPanel';
import IssuesOverviewScreen from '../../../components/logic/screens/IssuesOverviewScreen';
import KickstartPage from '~/components/app/KickstartPage';
import MemberDetailPanel from '../../../components/logic/memberDetail/MemberDetailPanel';
import MultiDashboardScreen from '../../../components/logic/screens/MultiDashboardScreen';
import SegmentEditorScreen from '../../../components/logic/screens/SegmentEditorScreen';
import StaticSegmentEditorScreen from '~/components/app/StaticSegmentEditorScreen';
import PageDetailScreen from '~/components/app/PageDetail/PageDetailScreen';
import PageIssuesCategory from '../../../components/atoms/issues/views/PageIssuesCategory';
import PageSnapshotPanel from '../../../components/logic/pageDetail/PageSnapshotPanel';
import PlatformScreen from '../../../components/logic/screens/PlatformScreen';
import PlatformScreenIssuesCategory from '../../../components/logic/platform/issues/issueDetails/PlatformScreenIssuesCategory';
import PagesOverviewPage from '../../../components/views/authenticated/PagesOverviewPage';
import PruneOrphansModal from '~/components/app/PruneOrphansModal';
import RefreshEnrichmentApiTokenModal from '~/components/app/RefreshEnrichmentApiTokenModal';
import RefreshReportingApiTokenModal from '~/components/app/RefreshReportingApiTokenModal';
import SingleIssueAffectedPagesPanel from '../../../components/views/authenticated/SingleIssueAffectedPagesPanel';
import SitemapsListScreen from '../../../components/logic/screens/SitemapsListScreen';
import RobotsTxtViewerScreen from '~/components/app/RobotsTxtViewerScreen';
import RobotsTxtComparisonScreen from '~/components/app/RobotsTxtComparisonScreen';
import SetupTwoFactorAuthenticationModal from '~/components/app/SetupTwoFactorAuthenticationModal';
import AccountScreen from '../../../components/logic/screens/AccountScreen';
import UserProfileScreen from '~/components/logic/memberDetail/UserProfileScreen';
import WebsiteDetailScreen from '~/components/app/WebsiteDetailScreen';
import WebsiteIssuesCategory from '../../../components/atoms/issues/views/WebsiteIssuesCategory';
import IgnorePlatformIssueModal from '../../../components/atoms/modals/IgnorePlatformIssueModal';
import IgnoreWebsiteIssueCategoryModal from '../../../components/atoms/modals/IgnoreWebsiteIssueCategoryModal';
import IgnoreWebsiteIssueModal from '../../../components/atoms/modals/IgnoreWebsiteIssueModal';
import IgnorePageIssueCategoryModal from '../../../components/atoms/modals/IgnorePageIssueCategoryModal';
import IgnorePageIssueModal from '../../../components/atoms/modals/IgnorePageIssueModal';
import {
	ALIGNMENT_TOP as MODAL_ALIGNMENT_TOP,
	SIZE_LARGE as MODAL_SIZE_LARGE,
	SIZE_XXLARGE as MODAL_SIZE_XXLARGE,
} from '../../../components/atoms/panels/ModalPanel';
import SignupModal from '../../../components/app/SignupModal/SignupModal';

import {
	CHANGE_URL_STATE,
} from '~/actions';

import {
	ExportType,
} from '~/model/exports';

import {
	IgnoringScope,
} from '~/model/issuesNew';

import {
	getAbsoluteCategoryType,
	getAbsoluteIssueType,
} from '~/model/issues/identifierMapping';

import {
	PAGES_SECTION_GRAPHS,
	PAGES_SECTION_TABLE,
} from '~/model/ui/pagesScreen';



const interestingSections = [
	'account.members',
	'account.websites',
	'website.alerts',
	'website.alerts.alertPages',
	'website.pages',
	'website.issues.issueCategory.singleIssueAffectedLinks',
	'website.issues.issueCategory.singleIssueAffectedPages',
	'website.issues.issueCategory.affectedPages',
];



function createDefaultState() {
	return {
		stack: [],
		previousVisitedSection: Map(),
	};
}



function setupContentAccordingToUrlState(urlState, previousUrlState, previousState) {
	if (!(previousState instanceof Map)) {
		previousState = Map(previousState);
	}

	let state = {
		stack: [],
		current: undefined,
		pageDetailTab: previousState ? previousState.get('pageDetailTab') : null,
		pageDetailIssueType: null,
		pageDetailIssuesCategoryType: null,
		pagesColumnsParameter: previousState ? previousState.get('pagesColumnsParameter') : null,
		pagesFilterParameter: previousState ? previousState.get('pagesFilterParameter') : null,
		pagesSection: previousState ? previousState.get('pagesSection') : PAGES_SECTION_TABLE,
		pagesViewParameter: previousState ? previousState.get('pagesViewParameter') : null,
		issuesFilterParameter: previousState ? previousState.get('issuesFilterParameter') : null,
		teamDetailTab: 'websites',
		historicalChangesStartDate: previousState ? previousState.get('historicalChangesStartDate') : null,
		historicalChangesEndDate: previousState ? previousState.get('historicalChangesEndDate') : null,
		previousVisitedSection: previousState ? previousState.get('previousVisitedSection') : new Map(),
	};

	if (previousUrlState && interestingSections.indexOf(previousUrlState.name) >= 0) {
		let backlink = null;

		if (previousUrlState.name === 'website.issues.issueCategory.singleIssueAffectedLinks') {
			backlink = 'affectedLinks';
		} else if (previousUrlState.name === 'website.alerts.alertPages') {
			backlink = 'alertPages';
		} else if (previousUrlState.name === 'website.alerts') {
			backlink = 'alerts';
		} else if (previousUrlState.name === 'website.pages') {
			backlink = 'pagesOverview';
		} else if (previousUrlState.name === 'account.websites') {
			backlink = 'account.websites';
		} else if (previousUrlState.name === 'account.members') {
			backlink = 'account.members';
		} else {
			backlink = 'affectedPages';
		}

		state.previousVisitedSection = new Map({
			backlink,
			name: previousUrlState.name,
			params: previousUrlState.params,
		});
	}

	const nodes = urlState
		? urlState.name.split('.')
		: [null];

	switch (nodes[0]) {

		case 'home': {
			state.stack[0] = {
				content: HomePage,
			};

			state.current = HomePage;

			break;
		}

		case 'kickstart': {
			state.stack[0] = {
				content: KickstartPage,
			};

			state.current = KickstartPage;

			break;
		}

		case 'login': {
			if (previousState) {
				state = previousState.toJS();
				state.previousVisitedSection = Map(state.previousVisitedSection);
			}

			// <hack-alert>When user is logged in and goes to 'login' page this will skip login page which would be blank anyway.</hack-alert>
			state.stack[0] = {
				content: HomePage,
			};

			state.current = HomePage;

			break;
		}

		case 'entry': {
			state.stack[0] = {
				content: EntryPage,
			};

			state.current = EntryPage;

			break;
		}

		case 'empty_state': {
			state.stack[0] = {
				content: EmptyAppScreen,
			};

			state.current = EmptyAppScreen;

			break;
		}

		case 'website': {
			switch (nodes[1]) {

				case 'pages': {
					let params = {
						websiteId: urlState.params.websiteId,
					};

					if (urlState.name === 'website.pages' || urlState.name === 'website.pages.datepicker') {
						state.pagesColumnsParameter = urlState.params.columns;
						state.pagesFilterParameter = urlState.params.filter;
						state.pagesViewParameter = urlState.params.view;
						state.historicalChangesStartDate = urlState.params.start_date;
						state.historicalChangesEndDate = urlState.params.end_date;
						state.pagesSection = urlState.params.graphs
							? PAGES_SECTION_GRAPHS
							: PAGES_SECTION_TABLE;
					}

					if (state.pagesFilterParameter) {
						params.filter = state.pagesFilterParameter;
					}

					if (state.pagesViewParameter) {
						params.view = state.pagesViewParameter;
					}

					if (state.pagesColumnsParameter) {
						params.columns = state.pagesColumnsParameter;
					}

					if (state.historicalChangesStartDate) {
						params.start_date = state.historicalChangesStartDate;
					}

					if (state.historicalChangesEndDate) {
						params.end_date = state.historicalChangesEndDate;
					}

					if (state.pagesSection === PAGES_SECTION_TABLE) {
						delete params.graphs;
					} else if (state.pagesSection === PAGES_SECTION_GRAPHS) {
						params.graphs = true;
					}

					state.stack[0] = {
						content: PagesOverviewPage,
					};

					if (state.previousVisitedSection?.size > 0 && urlState.name !== 'website.pages.datepicker') {
						params = state.previousVisitedSection.get('params');
					}

					state.stack[1] = {
						backlink: state.previousVisitedSection?.get('backlink') || 'pagesOverview',
						closeRoute: {
							name: state.previousVisitedSection?.get('name') || 'website.pages',
							params,
						},
						content: null,
						type: 'fullscreen',
					};

					state.current = PagesOverviewPage;

					switch (nodes[2]) {

						case 'datepicker': {
							state.stack[1].content = DatePickerPanel;
							state.stack[1].type = 'mobile-only';
							state.stack[1].backlink = 'closeHistoricalChangesMobileFilter';
							state.stack[1].closeRoute = { name: 'website.pages', params };

							state.current = DatePickerPanel;
							state.usageScope = DATE_PICKER_SCOPE_PAGES;

							break;
						}

						case 'export': {
							state.stack[1].content = ExportPanel;
							state.stack[1].type = 'mobile-only';

							state.contextType = ExportType.ContentOverview;

							state.current = ExportPanel;

							break;
						}

						case 'detail': {
							state.stack[1].content = PageDetailScreen;

							state.stack[2] = {
								backlink: 'pageDetail',
								closeRoute: {
									name: (
										state.pageDetailTab
											? `website.pages.detail.${state.pageDetailTab}`
											: 'website.pages.detail'
									),
									params: {
										websiteId: urlState.params.websiteId,
										id: urlState.params.id,
									},
								},
								content: PageIssuesCategory,
								type: 'detail',
							};

							state.current = PageDetailScreen;

							switch (nodes[3]) {

								case 'issues': {
									state.pageDetailTab = 'issues';

									break;
								}

								case 'history': {
									state.pageDetailTab = 'history';

									switch (nodes[4]) {

										case 'snapshot': {
											state.stack[2] = {
												backlink: 'pageDetail',
												closeRoute: {
													name: 'website.pages.detail.history',
													params: {
														websiteId: urlState.params.websiteId,
														id: urlState.params.id,
													},
												},
												content: PageSnapshotPanel,
												type: 'fullscreen-detail',
											};

											state.current = PageSnapshotPanel;

											break;
										}

									}

									break;
								}

								case 'links': {
									state.stack[2] = {
										backlink: 'pageDetail',
										closeRoute: {
											name: 'website.pages.detail',
											params: {
												websiteId: urlState.params.websiteId,
												id: urlState.params.id,
											},
										},
										content: ContentRelationsPanel,
										type: 'fullscreen-detail',
									};

									state.current = ContentRelationsPanel;

									break;
								}

								case 'issuesCategory': {
									state.pageDetailTab = 'issues';

									state.pageDetailIssuesCategoryType = getAbsoluteCategoryType(
										urlState.params.pageDetailIssuesCategoryType,
									);

									state.current = PageIssuesCategory;

									switch (nodes[4]) {

										case 'categoryIgnoring': {
											state.pageDetailIgnoringScope = urlState.params.scope || IgnoringScope.Page;

											state.stack[3] = {
												backlink: 'issuesCategory',
												closeRoute: {
													name: 'website.pages.detail.issuesCategory',
													params: {
														websiteId: urlState.params.websiteId,
														id: urlState.params.id,
														pageDetailIssuesCategoryType: urlState.params.pageDetailIssuesCategoryType,
													},
												},
												content: IgnorePageIssueCategoryModal,
												type: 'modal',
											};

											state.current = IgnorePageIssueCategoryModal;

											break;
										}

										case 'issueIgnoring': {
											state.pageDetailIssue = getAbsoluteIssueType(
												urlState.params.pageDetailIssuesCategoryType,
												urlState.params.pageDetailIssue,
											);
											state.pageDetailIgnoringScope = urlState.params.scope || IgnoringScope.Page;

											state.stack[3] = {
												backlink: 'issuesCategory',
												closeRoute: {
													name: 'website.pages.detail.issuesCategory',
													params: {
														websiteId: urlState.params.websiteId,
														id: urlState.params.id,
														pageDetailIssuesCategoryType: urlState.params.pageDetailIssuesCategoryType,
													},
												},
												content: IgnorePageIssueModal,
												type: 'modal',
											};

											state.current = IgnorePageIssueModal;

											break;
										}

									}

									break;
								}

								default: {
									state.pageDetailTab = null;

									break;
								}

							}

							break;
						}

						default: {
							break;
						}

					}

					break;
				}

				case 'issues': {
					if (
						urlState.name === 'website.issues'
						|| urlState.name === 'website.issues.issueCategory'
						|| urlState.name === 'website.issues.categoryIgnoring'
						|| urlState.name === 'website.issues.issueIgnoring'
					) {
						state.issuesFilterParameter = urlState.params.filter;
					}

					state.stack[0] = {
						content: IssuesOverviewScreen,
					};
					const params = {
						websiteId: urlState.params.websiteId,
						start_date: urlState.params.start_date,
						end_date: urlState.params.end_date,
					};
					if (state.issuesFilterParameter) {
						params.filter = state.issuesFilterParameter;
					}

					state.stack[1] = {
						backlink: 'issuesOverview',
						closeRoute: { name: 'website.issues', params },
						content: WebsiteIssuesCategory,
						type: 'detail',
					};

					state.issuesOverviewCategory = null;

					state.current = IssuesOverviewScreen;

					switch (nodes[2]) {

						case 'datepicker': {
							state.stack[1].content = DatePickerPanel;
							state.stack[1].type = 'mobile-only';
							state.stack[1].backlink = 'closeHistoricalChangesMobileFilter';
							state.stack[1].closeRoute = { name: 'website.issues', params };

							state.current = DatePickerPanel;
							state.usageScope = DATE_PICKER_SCOPE_ISSUES;

							break;
						}

						case 'issueCategory': {
							state.issuesOverviewCategory = getAbsoluteCategoryType(
								urlState.params.issuesOverviewCategory,
							);

							state.current = WebsiteIssuesCategory;

							switch (nodes[3]) {

								case 'affectedPages': {
									state.stack[2] = {
										backlink: 'issueDetail',
										closeRoute: {
											name: 'website.issues.issueCategory',
											params: {
												filter: state.issuesFilterParameter,
												websiteId: urlState.params.websiteId,
												issuesOverviewCategory: urlState.params.issuesOverviewCategory,
												start_date: urlState.params.start_date,
												end_date: urlState.params.end_date,
											},
										},
										content: AffectedPagesPanel,
										type: 'fullscreen',
									};
									state.stack[3] = {
										backlink: 'affectedPages',
										closeRoute: {
											name: 'website.issues.issueCategory.affectedPages',
											params: {
												filter: urlState.params.filter,
												websiteId: urlState.params.websiteId,
												issuesOverviewCategory: urlState.params.issuesOverviewCategory,
												start_date: urlState.params.start_date,
												end_date: urlState.params.end_date,
											},
										},
										content: null,
										type: 'normal',
									};

									state.current = AffectedPagesPanel;

									switch (nodes[4]) {

										case 'datepicker': {
											state.stack[3].content = DatePickerPanel;
											state.stack[3].type = 'mobile-only';

											state.current = DatePickerPanel;
											state.usageScope = DATE_PICKER_SCOPE_AFFECTED_PAGES;

											break;
										}

										case 'export': {
											state.stack[3].content = ExportPanel;
											state.stack[3].type = 'mobile-only';

											state.contextType = ExportType.AffectedPages;

											state.current = ExportPanel;

											break;
										}

									}

									break;
								}

								case 'configuration': {
									state.stack[2] = {
										backlink: 'issueDetail',
										closeRoute: {
											name: 'website.issues.issueCategory',
											params: {
												filter: state.issuesFilterParameter,
												websiteId: urlState.params.websiteId,
												issuesOverviewCategory: urlState.params.issuesOverviewCategory,
											},
										},
										content: IssueCategoryConfigurationModal,
										type: 'modal',
									};

									state.current = IssueCategoryConfigurationModal;

									break;
								}

								case 'categoryIgnoring': {
									state.issuesOverviewIgnoringScope = urlState.params.scope || IgnoringScope.Website;
									state.stack[2] = {
										backlink: 'issueDetail',
										closeRoute: {
											name: 'website.issues.issueCategory',
											params: {
												filter: state.issuesFilterParameter,
												websiteId: urlState.params.websiteId,
												issuesOverviewCategory: urlState.params.issuesOverviewCategory,
											},
										},
										content: IgnoreWebsiteIssueCategoryModal,
										type: 'modal',
									};

									state.current = IgnoreWebsiteIssueCategoryModal;

									break;
								}

								case 'issueIgnoring': {
									state.issuesOverviewIssue = getAbsoluteIssueType(
										urlState.params.issuesOverviewCategory,
										urlState.params.issuesOverviewIssue,
									);
									state.issuesOverviewIgnoringScope = urlState.params.scope || IgnoringScope.Website;
									state.stack[2] = {
										backlink: 'issueDetail',
										closeRoute: {
											name: 'website.issues.issueCategory',
											params: {
												filter: state.issuesFilterParameter,
												websiteId: urlState.params.websiteId,
												issuesOverviewCategory: urlState.params.issuesOverviewCategory,
											},
										},
										content: IgnoreWebsiteIssueModal,
										type: 'modal',
									};

									state.current = IgnoreWebsiteIssueModal;

									break;
								}

								case 'singleIssueAffectedLinks': {
									state.issuesOverviewIssue = getAbsoluteIssueType(
										urlState.params.issuesOverviewCategory,
										urlState.params.issuesOverviewIssue,
									);

									state.stack[2] = {
										backlink: 'issueDetail',
										closeRoute: {
											name: 'website.issues.issueCategory',
											params: {
												filter: state.issuesFilterParameter,
												websiteId: urlState.params.websiteId,
												issuesOverviewCategory: urlState.params.issuesOverviewCategory,
											},
										},
										content: AffectedLinksScreen,
										type: 'fullscreen',
									};

									state.current = AffectedLinksScreen;

									break;
								}

								case 'singleIssueAffectedPages': {
									state.issuesOverviewIssue = getAbsoluteIssueType(
										urlState.params.issuesOverviewCategory,
										urlState.params.issuesOverviewIssue,
									);
									state.stack[2] = {
										backlink: 'issueDetail',
										closeRoute: {
											name: 'website.issues.issueCategory',
											params: {
												filter: state.issuesFilterParameter,
												websiteId: urlState.params.websiteId,
												issuesOverviewCategory: urlState.params.issuesOverviewCategory,
												start_date: urlState.params.start_date,
												end_date: urlState.params.end_date,
											},
										},
										content: SingleIssueAffectedPagesPanel,
										type: 'fullscreen',
									};

									state.current = SingleIssueAffectedPagesPanel;

									switch (nodes[4]) {

										case 'datepicker': {
											state.stack[3].content = DatePickerPanel;
											state.stack[3].type = 'mobile-only';

											state.current = DatePickerPanel;
											state.usageScope = DATE_PICKER_SCOPE_SINGLE_ISSUE_AFFECTED_PAGES;

											break;
										}

										case 'export': {
											state.stack[2].content = ExportPanel;
											state.stack[2].type = 'mobile-only';

											state.contextType = ExportType.SingleIssueAffectedPages;

											state.current = ExportPanel;

											break;
										}

									}

									break;
								}

								default: {
									break;
								}

							}

							break;
						}

						case 'filter': {
							state.stack[1] = {
								backlink: 'issuesOverview',
								closeRoute: {
									name: 'website.issues',
									params: {
										end_date: urlState.params.end_date,
										filter: urlState.params.filter,
										start_date: urlState.params.start_date,
										websiteId: urlState.params.websiteId,
									},
								},
								content: IssuesOverviewFilterPanel,
								type: 'mobile-only',
							};

							state.current = IssuesOverviewFilterPanel;

							break;
						}

						default: {
							break;
						}

					}

					break;
				}

				case 'alerts': {
					state.stack[0] = {
						content: AlertsScreen,
					};

					state.openEvent = undefined;

					if (urlState.params.event || urlState.params.alert) {
						state.openEvent = {
							type: null,
							id: parseInt(urlState.params.event || urlState.params.alert, 10),
						};

						if (urlState.params.event) {
							state.openEvent.type = 'event';
						} else if (urlState.params.alert) {
							state.openEvent.type = 'alert';
						}

						state.openEvent = Map(state.openEvent);
					}

					state.current = AlertsScreen;

					switch (nodes[2]) {

						case 'configuration': {
							state.stack[1] = {
								backlink: 'alerts',
								closeRoute: {
									name: 'website.alerts',
									params: {
										websiteId: urlState.params.websiteId,
									},
								},
								content: AlertsConfigurationScreen,
								type: 'fullscreen',
							};

							state.current = AlertsConfigurationScreen;

							switch (nodes[3]) {

								case 'createDefinitions': {
									state.stack[2] = {
										alignment: MODAL_ALIGNMENT_TOP,
										backlink: 'alerts',
										closeRoute: {
											name: 'website.alerts.configuration',
											params: {
												websiteId: urlState.params.websiteId,
											},
										},
										content: CreateAlertDefinitionsModal,
										type: 'modal',
										canBeClosedViaOverlay: false,
										size: MODAL_SIZE_XXLARGE,
									};

									state.current = CreateAlertDefinitionsModal;

									break;
								}

								case 'editDefinition': {
									state.stack[2] = {
										backlink: 'alerts',
										closeRoute: {
											name: 'website.alerts.configuration',
											params: {
												websiteId: urlState.params.websiteId,
											},
										},
										content: EditAlertDefinitionModal,
										type: 'modal',
										canBeClosedViaOverlay: true,
										size: MODAL_SIZE_XXLARGE,
									};

									state.current = EditAlertDefinitionModal;

									break;
								}

								case 'editDefinitionsMessagingApp': {
									state.stack[2] = {
										backlink: 'alerts',
										closeRoute: {
											name: 'website.alerts.configuration',
											params: {
												websiteId: urlState.params.websiteId,
											},
										},
										content: EditAlertDefinitionsMessagingAppModal,
										type: 'modal',
										canBeClosedViaOverlay: true,
									};

									state.current = EditAlertDefinitionsMessagingAppModal;

									break;
								}

								case 'editDefinitionsRecipients': {
									state.stack[2] = {
										backlink: 'alerts',
										closeRoute: {
											name: 'website.alerts.configuration',
											params: {
												websiteId: urlState.params.websiteId,
											},
										},
										content: EditAlertDefinitionsRecipientsModal,
										type: 'modal',
										canBeClosedViaOverlay: true,
									};

									state.current = EditAlertDefinitionsRecipientsModal;

									break;
								}

								case 'editDefinitionsScope': {
									state.stack[2] = {
										backlink: 'alerts',
										closeRoute: {
											name: 'website.alerts.configuration',
											params: {
												websiteId: urlState.params.websiteId,
											},
										},
										content: EditAlertDefinitionsScopeModal,
										type: 'modal',
										canBeClosedViaOverlay: true,
									};

									state.current = EditAlertDefinitionsScopeModal;

									break;
								}

								case 'editDefinitionsSensitivity': {
									state.stack[2] = {
										backlink: 'alerts',
										closeRoute: {
											name: 'website.alerts.configuration',
											params: {
												websiteId: urlState.params.websiteId,
											},
										},
										content: EditAlertDefinitionsSensitivityModal,
										type: 'modal',
										canBeClosedViaOverlay: true,
									};

									state.current = EditAlertDefinitionsSensitivityModal;

									break;
								}

							}

							break;
						}

						case 'alertPages': {
							state.alertId = parseInt(
								urlState.params.alertId,
							);

							state.stack[1] = {
								backlink: 'alerts',
								closeRoute: {
									name: 'website.alerts',
									params: {
										websiteId: urlState.params.websiteId,
									},
								},
								content: AlertPagesScreen,
								type: 'fullscreen',
							};

							state.current = AlertPagesScreen;

							break;
						}

					}

					break;
				}

				case 'platform': {
					state.stack[0] = {
						content: PlatformScreen,
					};

					state.stack[1] = {
						backlink: 'platform',
						closeRoute: {
							name: 'website.platform',
							params: {
								websiteId: urlState.params.websiteId,
							},
						},
						content: PlatformScreenIssuesCategory,
						type: 'detail',
					};

					state.current = PlatformScreen;

					switch (nodes[2]) {

						case 'issuesCategory': {
							state.platformScreenIssuesCategoryType = getAbsoluteCategoryType(
								urlState.params.platformScreenIssuesCategoryType,
							);

							state.current = PlatformScreenIssuesCategory;

							switch (nodes[3]) {

								case 'issueIgnoring': {
									state.platformIssue = getAbsoluteIssueType(
										urlState.params.platformScreenIssuesCategoryType,
										urlState.params.platformIssue,
									);
									state.platformIgnoringScope = urlState.params.scope || IgnoringScope.Website;

									state.stack[2] = {
										backlink: 'issuesCategory',
										closeRoute: {
											name: 'website.platform.issuesCategory',
											params: {
												websiteId: urlState.params.websiteId,
												platformScreenIssuesCategoryType: urlState.params.platformScreenIssuesCategoryType,
											},
										},
										content: IgnorePlatformIssueModal,
										type: 'modal',
									};

									state.current = IgnorePlatformIssueModal;

									break;
								}

							}

							break;
						}

						case 'sitemaps': {
							state.stack[1] = {
								backlink: 'platform',
								closeRoute: {
									name: 'website.platform',
									params: {
										websiteId: urlState.params.websiteId,
									},
								},
								content: SitemapsListScreen,
								type: 'fullscreen',
							};

							state.current = SitemapsListScreen;

							break;
						}

						case 'robotsTxtViewer': {
							state.stack[1] = {
								backlink: 'platform',
								closeRoute: {
									name: 'website.platform',
									params: {
										websiteId: urlState.params.websiteId,
									},
								},
								content: RobotsTxtViewerScreen,
								type: 'fullscreen',
							};

							state.current = RobotsTxtViewerScreen;

							break;
						}

						case 'robotsTxtComparison': {
							state.stack[1] = {
								backlink: 'platform',
								closeRoute: {
									name: 'website.platform',
									params: {
										websiteId: urlState.params.websiteId,
									},
								},
								content: RobotsTxtComparisonScreen,
								type: 'fullscreen',
							};

							state.current = RobotsTxtComparisonScreen;

							break;
						}

					}

					break;
				}

				case 'dashboard': {
					state.stack[0] = {
						content: DashboardScreen,
					};

					state.current = DashboardScreen;

					break;
				}

				case 'segmentEditor': {
					state.stack[0] = {
						content: SegmentEditorScreen,
					};

					state.current = SegmentEditorScreen;

					break;
				}

				case 'staticSegmentEditor': {
					state.stack[0] = {
						content: StaticSegmentEditorScreen,
					};

					state.current = StaticSegmentEditorScreen;

					break;
				}

				case 'detail': {
					state.stack[0] = {
						backlink: state.previousVisitedSection?.get('backlink'),
						closeRoute: state.previousVisitedSection?.has('backlink') && {
							name: state.previousVisitedSection.get('name'),
							params: state.previousVisitedSection.get('params'),
						},
						content: WebsiteDetailScreen,
					};

					state.current = WebsiteDetailScreen;

					break;
				}

				default: {
					break;
				}

			}

			break;
		}

		case 'generalAdmin': {
			state.stack[0] = {
				content: GeneralAdminScreen,
			};

			state.current = GeneralAdminScreen;

			break;
		}

		case 'account': {
			state.stack[0] = {
				content: AccountScreen,
			};

			state.current = AccountScreen;

			state.teamDetailTab = nodes[1] || 'websites';
			switch (nodes[2]) {

				case 'member':
					state.stack[1] = {
						backlink: 'members',
						closeRoute: {
							name: 'account.members',
							params: {
								accountId: urlState.params.accountId,
							},
						},
						content: MemberDetailPanel,
						type: 'carousel-detail',
					};

					state.current = MemberDetailPanel;

					break;

				case 'new':
					switch (nodes[1]) {

						case 'websites': {
							state.stack[1] = {
								alignment: MODAL_ALIGNMENT_TOP,
								backlink: 'websites',
								closeRoute: {
									name: 'account.websites',
									params: {
										accountId: urlState.params.accountId,
										view: urlState.params.view,
									},
								},
								content: AddWebsiteModal,
								size: MODAL_SIZE_XXLARGE,
								type: 'modal',
								canBeClosedViaOverlay: false,
							};

							state.current = AddWebsiteModal;

							break;
						}

						case 'members': {
							state.stack[1] = {
								alignment: MODAL_ALIGNMENT_TOP,
								backlink: 'members',
								closeRoute: {
									name: 'account.members',
									params: {
										accountId: urlState.params.accountId,
									},
								},
								content: AddMemberModal,
								size: MODAL_SIZE_XXLARGE,
								type: 'modal',
								canBeClosedViaOverlay: false,
							};

							state.current = AddMemberModal;

							break;
						}

					}

					break;

			}

			if (nodes[1] === 'settings') {
				switch (nodes[3]) {

					case 'acceptEnrichmentApiTermsOfUse': {
						state.stack[1] = {
							backlink: 'settings',
							closeRoute: {
								name: 'account.settings.integrationTokens',
								params: {
									accountId: urlState.params.accountId,
								},
							},
							content: AcceptEnrichmentApiTermsOfUseModal,
							type: 'modal',
							canBeClosedViaOverlay: false,
						};

						state.current = AcceptEnrichmentApiTermsOfUseModal;

						break;
					}

					case 'acceptReportingApiTermsOfUse': {
						state.stack[1] = {
							backlink: 'settings',
							closeRoute: {
								name: 'account.settings.integrationTokens',
								params: {
									accountId: urlState.params.accountId,
								},
							},
							content: AcceptReportingApiTermsOfUseModal,
							type: 'modal',
							canBeClosedViaOverlay: false,
						};

						state.current = AcceptReportingApiTermsOfUseModal;

						break;
					}

					case 'changeEnrichmentApiToken': {
						state.stack[1] = {
							backlink: 'settings',
							closeRoute: {
								name: 'account.settings.integrationTokens',
								params: {
									accountId: urlState.params.accountId,
								},
							},
							content: RefreshEnrichmentApiTokenModal,
							type: 'modal',
							canBeClosedViaOverlay: false,
						};

						state.current = RefreshEnrichmentApiTokenModal;

						break;
					}

					case 'changeReportingApiToken': {
						state.stack[1] = {
							backlink: 'settings',
							closeRoute: {
								name: 'account.settings.integrationTokens',
								params: {
									accountId: urlState.params.accountId,
								},
							},
							content: RefreshReportingApiTokenModal,
							type: 'modal',
							canBeClosedViaOverlay: false,
						};

						state.current = RefreshReportingApiTokenModal;

						break;
					}

					case 'grantAgencyAccess': {
						state.stack[1] = {
							backlink: 'settings',
							closeRoute: {
								name: 'account.settings.agencyAccess',
								params: {
									accountId: urlState.params.accountId,
								},
							},
							content: GrantAccessToAgencyModal,
							type: 'modal',
							canBeClosedViaOverlay: true,
						};

						state.current = GrantAccessToAgencyModal;

						break;
					}

				}
			}

			if (nodes[1] === 'connectedAccounts') {
				switch (nodes[2]) {

					case 'connectAccount': {
						state.stack[1] = {
							alignment: MODAL_ALIGNMENT_TOP,
							backlink: 'account.connectedAccounts',
							closeRoute: {
								name: 'account.connectedAccounts',
								params: {
									accountId: urlState.params.accountId,
								},
							},
							content: ConnectAccountModal,
							type: 'modal',
							canBeClosedViaOverlay: false,
							size: MODAL_SIZE_XXLARGE,
						};

						state.current = ConnectAccountModal;

						break;
					}

					case 'acceptAccessToClient': {
						state.stack[1] = {
							backlink: 'account.connectedAccounts',
							closeRoute: {
								name: 'account.connectedAccounts',
							},
							content: AcceptAccessToClientModal,
							type: 'modal',
							canBeClosedViaOverlay: true,
						};

						state.current = AcceptAccessToClientModal;

						break;
					}

				}
			}

			if (nodes[1] === 'pricing' && nodes[2] === 'signup') {
				state.stack[1] = {
					backlink: 'pricing',
					closeRoute: {
						name: 'account.pricing',
						params: {
							accountId: urlState.params.accountId,
						},
					},
					content: SignupModal,
					size: MODAL_SIZE_XXLARGE,
					type: 'modal',
					canBeClosedViaOverlay: false,
				};

				state.current = SignupModal;
			}

			break;
		}

		case 'multiDashboard': {
			state.stack[0] = {
				content: MultiDashboardScreen,
			};

			state.current = MultiDashboardScreen;

			break;
		}

		case 'userProfile': {
			state.stack[0] = {
				backlink: state.previousVisitedSection?.get('backlink'),
				content: UserProfileScreen,
				closeRoute: state.previousVisitedSection?.has('backlink') && {
					name: state.previousVisitedSection.get('name'),
					params: state.previousVisitedSection.get('params'),
				},
			};

			state.current = EntryPage;

			break;
		}

		default: {
			state.stack.push({
				content: Error404,
			});

			state.current = Error404;

			break;
		}

	}

	if (urlState.params.action === 'change_role') {
		state.stack.push({
			backlink: 'members',
			closeRoute: {
				name: urlState.name,
				params: Object.assign({}, urlState.params, {
					action: false,
				}),
			},
			content: ChangeUserRoleModal,
			size: MODAL_SIZE_LARGE,
			type: 'modal',
			canBeClosedViaOverlay: false,
		});

		state.current = ChangeUserRoleModal;
	} else if (urlState.params.action === 'change_password') {
		state.stack.push({
			backlink: 'members',
			closeRoute: {
				name: urlState.name,
				params: Object.assign({}, urlState.params, {
					action: false,
				}),
			},
			content: ChangePasswordModal,
			type: 'modal',
			canBeClosedViaOverlay: false,
		});

		state.current = ChangePasswordModal;
	} else if (
		urlState.params.action === 'setup_two_factor_authentication'
	) {
		state.stack.push({
			backlink: 'settings',
			closeRoute: {
				name: urlState.name,
				params: Object.assign({}, urlState.params, {
					action: false,
				}),
			},
			content: SetupTwoFactorAuthenticationModal,
			size: MODAL_SIZE_XXLARGE,
			type: 'modal',
			canBeClosedViaOverlay: false,
		});

		state.current = SetupTwoFactorAuthenticationModal;
	} else if (
		urlState.params.action === 'disable_two_factor_authentication'
	) {
		state.stack.push({
			backlink: 'settings',
			closeRoute: {
				name: urlState.name,
				params: Object.assign({}, urlState.params, {
					action: false,
				}),
			},
			content: DisableTwoFactorAuthenticationModal,
			type: 'modal',
			canBeClosedViaOverlay: false,
		});

		state.current = DisableTwoFactorAuthenticationModal;
	} else if (urlState.params.action === 'exclusion_list') {
		state.stack.push({
			backlink: 'website',
			closeRoute: {
				name: urlState.name,
				params: Object.assign({}, urlState.params, {
					action: false,
				}),
			},
			content: ExclusionsModal,
			size: MODAL_SIZE_XXLARGE,
			type: 'modal',
			canBeClosedViaOverlay: false,
		});

		state.current = ExclusionsModal;
	} else if (urlState.params.action === 'purge_orphan_pages') {
		state.stack.push({
			backlink: 'website',
			closeRoute: {
				name: urlState.name,
				params: Object.assign({}, urlState.params, {
					action: false,
				}),
			},
			content: PruneOrphansModal,
			type: 'modal',
			canBeClosedViaOverlay: true,
		});

		state.current = PruneOrphansModal;
	} else if (urlState.params.action === 'create_custom_element') {
		state.stack.push({
			backlink: 'website',
			closeRoute: {
				name: urlState.name,
				params: Object.assign({}, urlState.params, {
					action: false,
					customElementName: false,
				}),
			},
			content: CustomElementModal,
			size: MODAL_SIZE_XXLARGE,
			type: 'modal',
			canBeClosedViaOverlay: false,
		});

		state.current = CustomElementModal;
	} else if (urlState.params.action === 'create_enrichment_field') {
		state.stack.push({
			backlink: 'website',
			closeRoute: {
				name: urlState.name,
				params: Object.assign({}, urlState.params, {
					action: false,
					customElementName: false,
				}),
			},
			content: CreateEnrichmentFieldModal,
			size: MODAL_SIZE_XXLARGE,
			type: 'modal',
			canBeClosedViaOverlay: false,
		});

		state.current = CreateEnrichmentFieldModal;
	} else if (urlState.params.action === 'edit_custom_element') {
		state.stack.push({
			backlink: 'website',
			closeRoute: {
				name: urlState.name,
				params: Object.assign({}, urlState.params, {
					action: false,
					customElementName: false,
				}),
			},
			content: CustomElementModal,
			size: MODAL_SIZE_XXLARGE,
			type: 'modal',
			canBeClosedViaOverlay: false,
		});

		state.current = CustomElementModal;
	} else if (urlState.params.action === 'connect_to_account') {
		state.stack.push({
			backlink: 'settings',
			closeRoute: {
				name: urlState.name,
				params: Object.assign({}, urlState.params, {
					action: false,
				}),
			},
			content: ConnectToAgencyModal,
			type: 'modal',
			canBeClosedViaOverlay: false,
		});

		state.current = ConnectToAgencyModal;
	} else if (urlState.params.action === 'import_segments') {
		state.stack.push({
			backlink: 'website',
			closeRoute: {
				name: urlState.name,
				params: Object.assign({}, urlState.params, {
					action: false,
				}),
			},
			content: ImportSegmentsModal,
			size: MODAL_SIZE_XXLARGE,
			type: 'modal',
			canBeClosedViaOverlay: false,
		});

		state.current = ImportSegmentsModal;
	}

	return state;
}



export function content(state, action) {
	if (state === undefined) {
		state = createDefaultState();
	}

	switch (action.type) {

		case CHANGE_URL_STATE: {
			state = Map(setupContentAccordingToUrlState(
				action.urlState,
				action.previousUrlState,
				state,
			));

			break;
		}

	}

	return state;
}
