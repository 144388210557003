import React from 'react';
import {
	useSelector,
} from 'react-redux';

import FormsList from '~/components/atoms/lists/FormsList';
import WebsiteAdminDangerousEscapeHatchesForm from '~/components/app/WebsiteAdminDangerousEscapeHatchesForm';
import WebsiteAdminDebuggingForm from '~/components/app/WebsiteAdminDebuggingForm';
import WebsiteAdminFetchingSettingsForm from '~/components/app/WebsiteAdminFetchingSettingsForm';
import WebsiteAdminManageSuperConductorConfigurationForm from '~/components/app/WebsiteAdminManageSuperConductorConfigurationForm';
import WebsiteAdminNonstandardBehaviorForm from '~/components/app/WebsiteAdminNonstandardBehaviorForm';
import WebsiteAdminUsageOverview from '~/components/app/WebsiteAdminUsageOverview';
import WebsiteAdminVerificationForm from '~/components/app/WebsiteAdminVerificationForm';
import WebsiteCustomerTeamContextDetailsOverview from '~/components/app/WebsiteCustomerTeamContextDetailsOverview';
import WebsiteCustomerTeamGoogleSearchConsoleOverview from '~/components/app/WebsiteCustomerTeamGoogleSearchConsoleOverview';

import useWebsiteId from '~/hooks/useWebsiteId';

import {
	selectedWebsiteDetailSelector,
} from '~/state/ui/selectors';



const WebsiteDetailScreenAdminSection: React.FC = () => {
	const websiteId = useWebsiteId() as ReturnType<typeof useWebsiteId> | null;

	const websiteDetail = useSelector(selectedWebsiteDetailSelector);

	if (
		!websiteDetail
		|| websiteId === null
	) {
		return null;
	}

	return (
		<FormsList>
			<WebsiteAdminUsageOverview
				websiteId={websiteId}
			/>

			<WebsiteAdminVerificationForm
				websiteId={websiteId}
			/>

			<WebsiteCustomerTeamContextDetailsOverview
				websiteId={websiteId}
			/>

			<WebsiteCustomerTeamGoogleSearchConsoleOverview
				websiteId={websiteId}
			/>

			<WebsiteAdminDebuggingForm
				websiteId={websiteId}
			/>

			<WebsiteAdminNonstandardBehaviorForm
				websiteId={websiteId}
			/>

			<WebsiteAdminFetchingSettingsForm
				websiteId={websiteId}
			/>

			<WebsiteAdminDangerousEscapeHatchesForm
				websiteId={websiteId}
			/>

			<WebsiteAdminManageSuperConductorConfigurationForm
				websiteId={websiteId}
			/>
		</FormsList>
	);
};



export default WebsiteDetailScreenAdminSection;
