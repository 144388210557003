import React from 'react';
import {
	FormattedNumber,
} from 'react-intl';

import type CK from '~/types/contentking';

import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import Fraction from '~/components/logic/Fraction';
import Parenthesis from '~/components/logic/Parenthesis';
import StaticText from '~/components/atoms/forms/components/StaticText';

import {
	useWebsiteAdminUsageOverviewQuery,
} from './WebsiteAdminUsageOverview.gql';



type Props = {
	websiteId: CK.WebsiteId,
};

const WebsiteAdminUsageOverview: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const { data } = useWebsiteAdminUsageOverviewQuery({
		variables: {
			websiteId,
		},
	});

	const quotas = data?.website?.quotas ?? null;

	if (quotas === null) {
		return null;
	}

	const allCount = quotas.find((quota) => quota.type === 'all')?.count ?? 0;
	const nonpagesCount = quotas.find((quota) => quota.type === 'nonpages')?.count ?? 0;
	const nonpagesLimit = quotas.find((quota) => quota.type === 'nonpages')?.limit ?? 1000;
	const sitemapsCount = quotas.find((quota) => quota.type === 'sitemaps')?.count ?? 0;
	const sitemapsLimit = quotas.find((quota) => quota.type === 'sitemaps')?.limit ?? 1000;

	return (
		<EditableFormWrapper
			isForAdmins={true}
			isReadOnly={true}
			title="Usage overview"
		>
			<DisplayPart>
				<FormRows>
					<FormRow label="Non-pages usage">
						<StaticText>
							<Fraction
								partA={(
									<FormattedNumber value={nonpagesCount} />
								)}
								partB={(
									<FormattedNumber value={nonpagesLimit} />
								)}
							/>
							{' '}
							<span className="percentage">
								<Parenthesis
									content={(
										<FormattedNumber
											style="percent"
											value={nonpagesCount / nonpagesLimit}
										/>
									)}
								/>
							</span>
						</StaticText>
					</FormRow>

					<FormRow label="Sitemaps usage">
						<StaticText>
							<Fraction
								partA={(
									<FormattedNumber value={sitemapsCount} />
								)}
								partB={(
									<FormattedNumber value={sitemapsLimit} />
								)}
							/>
							{' '}
							<span className="percentage">
								<Parenthesis
									content={(
										<FormattedNumber
											style="percent"
											value={sitemapsCount / sitemapsLimit}
										/>
									)}
								/>
							</span>
						</StaticText>
					</FormRow>

					<FormRow label="Total URLs">
						<StaticText>
							<FormattedNumber value={allCount} />
						</StaticText>
					</FormRow>
				</FormRows>
			</DisplayPart>
		</EditableFormWrapper>
	);
};



export default WebsiteAdminUsageOverview;
