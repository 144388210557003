import {
	runAction,
} from '~/model/actions';

import {
	loadRobotsRulesViaApi,
	loadVirtualRobotsRulesViaApi,
	saveVirtualRobotsRulesViaApi,
} from '~/model/websites';



export const STORE_ROBOTS_RULES = 'STORE_ROBOTS_RULES';
export const STORE_UPDATED_WEBSITE = 'STORE_UPDATED_WEBSITE';
export const STORE_VIRTUAL_ROBOTS_RULES = 'STORE_VIRTUAL_ROBOTS_RULES';



export function loadRobotsRules(accountId, websiteId) {
	return (dispatch) => {
		return loadRobotsRulesViaApi(accountId, websiteId)
			.then(({ disallowed_rules }) => {
				dispatch(
					storeRobotsRules(
						accountId,
						websiteId,
						disallowed_rules,
					),
				);
			});
	};
}



export function loadVirtualRobotsRules(accountId, websiteId) {
	return (dispatch) => {
		return loadVirtualRobotsRulesViaApi(accountId, websiteId)
			.then(({ disallowed_rules }) => {
				dispatch(
					storeVirtualRobotsRules(
						accountId,
						websiteId,
						disallowed_rules,
					),
				);
				return Promise.resolve(disallowed_rules);
			});
	};
}



export function saveVirtualRobotsRules(accountId, websiteId, rules) {
	return (dispatch) => {
		return saveVirtualRobotsRulesViaApi(websiteId, rules)
			.then(() => {
				dispatch(
					storeVirtualRobotsRules(
						accountId,
						websiteId,
						rules,
					),
				);
				return Promise.resolve();
			});
	};
}



function storeRobotsRules(accountId, websiteId, rules) {
	return {
		type: STORE_ROBOTS_RULES,
		accountId,
		websiteId,
		rules,
	};
}



function storeUpdatedWebsite(website) {
	return {
		type: STORE_UPDATED_WEBSITE,
		website,
	};
}



function storeVirtualRobotsRules(accountId, websiteId, rules) {
	return {
		type: STORE_VIRTUAL_ROBOTS_RULES,
		accountId,
		websiteId,
		rules,
	};
}



export function updateWebsiteMonitoringSettings(input) {
	const {
		crawlingSpeed,
		fetchingLocation,
		httpAuth = null,
		renderingDeviceType,
		userAgentType,
		userAgentValue = null,
		websiteId,
	} = input;

	return (dispatch) => {
		const input: Record<string, unknown> = {
			crawlingSpeed,
			fetchingLocation,
			renderingDeviceType,
			userAgentType,
			userAgentValue,
			websiteId,
		};

		if (httpAuth !== null) {
			input.httpAuth = httpAuth;
		}

		return runAction({
			action: 'UpdateWebsiteMonitoringSettings',
			input,
		}).then(({ website }) => {
			dispatch(
				storeUpdatedWebsite(website),
			);
		});
	};
}
