import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import AddCustomElementButton from '~/components/app/AddCustomElementButton';
import ButtonsGroup from '~/components/patterns/buttons/ButtonsGroup';
import Copy from '~/components/logic/Copy';
import CopyCustomElementToWebsitesModal from '~/components/app/CopyCustomElementToWebsitesModal';
import DeleteCustomElementConfirmationModal from '~/components/app/DeleteCustomElementConfirmationModal';
import ElementTypeName from '~/components/logic/customElements/ElementTypeName';
import Ellipsis from '~/components/patterns/values/Ellipsis';
import InterfaceMessage from '~/components/patterns/messages/popup/InterfaceMessage';
import ManagementTable from '~/components/patterns/tables/ManagementTable';
import TableActionButton, {
	TableActionButtonIconType,
} from '~/components/patterns/tables/datatables/buttons/TableActionButton';
import WithPermission from '~/components/logic/access/WithPermission';

import useGetCustomElementDeletability from '~/hooks/useGetCustomElementDeletability';
import useKingdomAdminFeatures from '~/hooks/useKingdomAdminFeatures';
import useModals from '~/hooks/useModals';
import useViewportType from '~/hooks/useViewportType';
import useWebsiteCustomElementDefinitions from '~/hooks/useWebsiteCustomElementDefinitions';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	type CustomElementDefinition,
	CustomElementDeletionViabilityVerdict,
} from '~/model/customElements';

import {
	ObjectType,
} from '~/model/permissions';

import sortArrayByProperty from '~/utilities/sortArrayByProperty';



const messages = defineMessages({
	blankSlate: {
		id: 'ui.customElements.overview.blankSlate',
	},
	deletionDisabledDueToAlertDefinitions: {
		id: 'ui.customElements.overview.table.deletionDisabledDueToAlertDefinitions',
	},
	deletionDisabledDueToSegments: {
		id: 'ui.customElements.overview.table.deletionDisabledDueToSegments',
	},
	name: {
		id: 'ui.customElements.overview.table.header.name',
	},
	title: {
		id: 'ui.customElements.overview.title',
	},
	type: {
		id: 'ui.customElements.overview.table.header.type',
	},
});



const CustomElementsOverview = () => {
	const websiteId = useWebsiteId();

	const customElementDefinitions = useWebsiteCustomElementDefinitions(websiteId);
	const getCustomElementDeletability = useGetCustomElementDeletability(websiteId);

	const kingdomAdminFeatures = useKingdomAdminFeatures();
	const modals = useModals();
	const viewportType = useViewportType();

	const showCopyCustomElementToWebsitesButton = kingdomAdminFeatures.areVisible;
	const isManipulationAllowed = !viewportType.isSmall && !viewportType.isMedium;

	let blankSlate: React.ReactNode | null = null;

	if (customElementDefinitions.isLoaded && customElementDefinitions.count === 0) {
		blankSlate = (
			<InterfaceMessage>
				<FormattedMessage {...messages.blankSlate} />
			</InterfaceMessage>
		);
	}

	function openDeleteCustomElementConfirmationModal(customElementDefinition: CustomElementDefinition) {
		modals.openModal(
			() => (
				<DeleteCustomElementConfirmationModal
					customElementName={customElementDefinition.name}
					websiteId={websiteId}
				/>
			),
		);
	}

	function openCopyCustomElementToWebsitesModal(customElementDefinition: CustomElementDefinition) {
		modals.openModal(
			() => (
				<CopyCustomElementToWebsitesModal
					customElementLabel={customElementDefinition.label}
					customElementName={customElementDefinition.name}
				/>
			),
		);
	}

	const rows = React.useMemo(
		() => sortArrayByProperty(
			customElementDefinitions.listAll(),
			(customElementDefinition) => customElementDefinition.name,
		),
		[
			customElementDefinitions,
		],
	);

	return (
		<WithPermission
			action={GraphQL.ActionWithWebsite.ManageSettings}
			objectId={websiteId}
			objectType={ObjectType.Website}
			showMessage={false}
		>
			{({ isAllowed, message: permissionMessage }) => (
				<ManagementTable
					blankSlate={blankSlate}
					buttons={isManipulationAllowed && (
						<AddCustomElementButton />
					)}
					columnLabels={(
						<>
							<FormattedMessage {...messages.name} />
							<FormattedMessage {...messages.type} />
						</>
					)}
					columnWidths={(
						showCopyCustomElementToWebsitesButton
							? ['auto', 180, 140]
							: ['auto', 180, 95]
					)}
					height={[0, 500]}
					isLoading={customElementDefinitions.isLoaded === false}
					rows={rows}
					title={(
						<FormattedMessage {...messages.title} />
					)}
					width={[500, 700]}
				>
					{({ row: customElementDefinition }) => {
						const customElementDeletability = getCustomElementDeletability(customElementDefinition);

						let deleteButtonTooltip: React.ReactNode;

						if (customElementDeletability.verdict === CustomElementDeletionViabilityVerdict.PreventedDueToAlertDefinitions) {
							deleteButtonTooltip = (
								<Copy {...messages.deletionDisabledDueToAlertDefinitions} />
							);
						} else if (customElementDeletability.verdict === CustomElementDeletionViabilityVerdict.PreventedDueToSegmentDefinitions) {
							deleteButtonTooltip = (
								<Copy
									{...messages.deletionDisabledDueToSegments}
									values={{ segments: customElementDeletability.dependentSegments.length }}
								/>
							);
						}

						return (
							<>
								<Ellipsis>
									{customElementDefinition.label}
								</Ellipsis>

								<Ellipsis>
									<ElementTypeName elementType={customElementDefinition.dataType} />
								</Ellipsis>

								<ButtonsGroup>
									<TableActionButton
										disabled={isAllowed.yes === false}
										iconType={TableActionButtonIconType.CogWheel}
										linkRouteName="website.detail.customElements"
										linkRouteParams={{
											action: 'edit_custom_element',
											customElementName: customElementDefinition.name,
											websiteId,
										}}
										tooltip={permissionMessage ?? 'Edit custom element'}
									/>

									<TableActionButton
										disabled={isAllowed.yes === false || customElementDeletability.verdict !== CustomElementDeletionViabilityVerdict.NotPrevented}
										iconType={TableActionButtonIconType.Delete}
										onClick={() => {
											openDeleteCustomElementConfirmationModal(customElementDefinition);
										}}
										tooltip={permissionMessage ?? deleteButtonTooltip ?? 'Delete custom element'}
									/>

									{showCopyCustomElementToWebsitesButton && (
										<TableActionButton
											iconType={TableActionButtonIconType.Export}
											onClick={() => {
												openCopyCustomElementToWebsitesModal(customElementDefinition);
											}}
											tooltip="Import custom element"
										/>
									)}
								</ButtonsGroup>
							</>
						);
					}}
				</ManagementTable>
			)}
		</WithPermission>
	);
};



export default CustomElementsOverview;
