import React from 'react';
import sortBy from 'lodash/sortBy';

import {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CalloutMessage, {
	CalloutMessageStatus,
} from '~/components/patterns/messages/embedded/CalloutMessage';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import Ellipsis from '~/components/patterns/values/Ellipsis';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import List from '~/components/patterns/lists/List';
import ModalContentSection from '~/components/atoms/modals/parts/ModalContentSection';
import MultiselectTableField from '~/components/logic/formFields/MultiselectTableField';
import SegmentDefinitionIdentifier, {
	SegmentDefinitionIdentifierSize,
} from '~/components/logic/segments/SegmentDefinitionIdentifier';
import SimpleModal, {
	SimpleModalSize,
} from '~/components/patterns/modals/SimpleModal';
import SmallImageLabel from '~/components/patterns/images/SmallImageLabel';
import Spinner from '~/components/patterns/loaders/Spinner';
import SubmitButton from '~/components/app/SubmitButton';
import TextualList from '~/components/patterns/lists/TextualList';
import WebsiteFavicon, {
	WebsiteFaviconUsageContext,
} from '~/components/logic/websites/WebsiteFavicon';

import {
	useCopySegmentToWebsitesModalQuery,
	useCopySegmentToWebsitesMutation,
} from '~/components/app/CopySegmentToWebsitesModal.gql';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';
import useHomeAccountIds from '~/hooks/useHomeAccountIds';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';

import {
	type SegmentDefinition,
	filterSegmentDefinitionsByNames,
	listSegmentDependencies,
} from '~/model/segments';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';
import FormError from '~/utilities/FormError';

import {
	notEmpty,
} from '~/utilities/typeCheck';

import {
	unique,
} from '~/utilities/unique';



type Props = {
	segmentDefinition: SegmentDefinition,
};

const CopySegmentToWebsitesModal: React.FC<Props> = (props) => {
	const {
		segmentDefinition,
	} = props;

	const websiteId = useWebsiteId();

	const classicFormBehavior = useClassicFormBehavior();
	const homeAccountIds = useHomeAccountIds();
	const segmentDefinitions = useWebsiteSegmentDefinitions(websiteId);

	const {
		data,
		loading,
	} = useCopySegmentToWebsitesModalQuery({
		fetchPolicy: 'network-only',
	});
	const [copySegmentToWebsitesMutation] = useCopySegmentToWebsitesMutation();

	const rows = React.useMemo(
		() => {
			if (homeAccountIds === null) {
				return [];
			}

			const websites = sortBy(
				(data?.authenticatedSession?.accounts ?? []).flatMap(
					(account) => account.websites.map(
						(website) => ({
							...website,
							account,
						}),
					),
				),
				[
					(website) => homeAccountIds.includes(website.account.id),
					(website) => website.account.displayName,
					(website) => website.displayName,
				],
			);

			const segmentColumns = [
				...Object.keys(segmentDefinition.filterDefinition),
				(segmentDefinition.sizeLimit?.field ?? null) as string | null,
			].filter(notEmpty).filter(unique);

			const segmentCustomElements = segmentColumns.filter((column) => column.startsWith('custom_'));
			const segmentEnrichmentFields = segmentColumns.filter((column) => column.startsWith('ef_'));
			const segmentSegments = filterSegmentDefinitionsByNames(
				segmentDefinitions.listAll(),
				listSegmentDependencies(segmentDefinition.filterDefinition),
			);

			return websites
				.filter((website) => website.id !== websiteId)
				.map((website) => {
					const missingCustomElements = segmentCustomElements.filter((segmentCustomElement) => {
						return !website.customElements.find((websiteCustomElement) => {
							return `custom_${websiteCustomElement.name}` === segmentCustomElement;
						});
					});

					const missingEnrichmentFields = segmentEnrichmentFields.filter((segmentEnrichmentField) => {
						return !website.enrichmentFields.find((websiteEnrichmentField) => {
							return websiteEnrichmentField.column === segmentEnrichmentField;
						});
					});

					const missingSegments = segmentSegments.filter((segmentSegment) => {
						return !website.pageSegments.find((websiteSegment) => {
							return websiteSegment.name === segmentSegment.name;
						});
					});

					const existingSegment = website.pageSegments.find((websiteSegment) => {
						return (
							websiteSegment.label === segmentDefinition.label
							&& websiteSegment.shortcode === segmentDefinition.shortcode
							&& (websiteSegment.icon?.name ?? null) === (segmentDefinition.icon?.name ?? null)
							&& websiteSegment.color === segmentDefinition.color
						);
					}) ?? null;

					const disabled = (
						missingCustomElements.length > 0
						|| missingEnrichmentFields.length > 0
						|| missingSegments.length > 0
						|| existingSegment
					);

					return {
						account: website.account,
						disabled,
						existingSegment,
						missingCustomElements,
						missingEnrichmentFields,
						missingSegments,
						website,
					};
				});
		},
		[
			data,
			homeAccountIds,
			segmentDefinition,
			segmentDefinitions,
			websiteId,
		],
	);

	const handleSubmit = React.useCallback(
		async (values) => {
			const targetWebsiteIds = values.items.map((item) => {
				return item.website.id;
			});

			try {
				await copySegmentToWebsitesMutation({
					variables: {
						sourceSegmentId: segmentDefinition.id,
						sourceWebsiteId: websiteId,
						targetWebsiteIds,
					},
				});

				classicFormBehavior.finish();
			} catch (error) {
				throw FormError.fromApolloError(error);
			}
		},
		[
			classicFormBehavior,
			copySegmentToWebsitesMutation,
			segmentDefinition,
			websiteId,
		],
	);

	const isRowDisabled = React.useCallback(
		({ value }) => {
			return value.disabled;
		},
		[],
	);

	const renderCell = React.useCallback(
		(({ columnIndex, rowIndex }) => {
			const row = getArrayItemAtSafeIndex(rows, rowIndex);

			switch (columnIndex) {

				case 0:
					return (
						<SmallImageLabel
							image={(
								<WebsiteFavicon
									usageContext={WebsiteFaviconUsageContext.List}
									websiteId={row.website.id}
								/>
							)}
							label={(
								<Ellipsis>
									{row.website.displayName}
								</Ellipsis>
							)}
						/>
					);

				case 1:
					return (
						<Ellipsis>
							{row.account.displayName}
						</Ellipsis>
					);

			}

			return null;
		}),
		[
			rows,
		],
	);

	const renderDisabledRowExplanation = React.useCallback(
		({ item }) => {
			const messages: Array<any> = [];

			if (item.existingSegment !== null) {
				messages.push({
					label: 'Segment already exists on this website',
				});
			}

			if (item.missingCustomElements.length > 0) {
				messages.push({
					label: 'missing custom elements',
					description: item.missingCustomElements
						.map((column) => column.replace('custom_', ''))
						.join(', '),
				});
			}

			if (item.missingEnrichmentFields.length > 0) {
				messages.push({
					label: 'missing enrichment fields',
					description: item.missingEnrichmentFields
						.map((column) => column.replace('ef_', ''))
						.join(', '),
				});
			}

			if (item.missingSegments.length > 0) {
				messages.push({
					label: 'missing segments',
					description: (
						<List inline={true}>
							{item.missingSegments.map((segmentDefinition) => (
								<SegmentDefinitionIdentifier
									key={segmentDefinition.id}
									segmentDefinition={segmentDefinition}
									showCriteria={false}
									size={SegmentDefinitionIdentifierSize.Small}
								/>
							))}
						</List>
					),
				});
			}

			return (
				<TextualList
					items={messages}
					textOnlyWhenSingle={true}
				/>
			);
		},
		[],
	);

	const renderHeader = React.useCallback(
		({ columnIndex }) => {
			switch (columnIndex) {

				case 0:
					return 'Website';

				case 1:
					return 'Account';

			}

			return null;
		},
		[],
	);

	return (
		<SimpleModal
			alignToTop={true}
			iconType={BasicIconType.Export}
			sidebar={(
				<CalloutMessage
					borders={true}
					message="Caution!"
					status={CalloutMessageStatus.Warning}
				>
					<p>
						This action currently only checks whether Custom Elements, Enrichments Fields and/or
						Segments with the same name are present on the target websites.
					</p>

					<p>
						If they are present but have different definitions the copy of this segment might behave
						differently.
					</p>
				</CalloutMessage>
			)}
			size={SimpleModalSize.XXLarge}
			title="Copy segment to websites"
		>
			<Form
				defaultValues={{
					items: [],
				}}
				key={loading ? 'loading' : 'ready'}
				onSuccess={handleSubmit}
			>
				{({ values }) => (
					<>
						<ModalContentSection>
							<FormRow label="Segment">
								<div style={{ alignSelf: 'center' }}>
									<SegmentDefinitionIdentifier
										segmentDefinition={segmentDefinition}
										showCriteria={true}
										showEditLink={false}
									/>
								</div>
							</FormRow>
						</ModalContentSection>

						{loading ? (
							<Spinner />
						) : (
							<MultiselectTableField
								cellRenderer={renderCell}
								columnCount={2}
								columnWidth={({ index, width }) => [width * 0.6, width * 0.4][index]}
								disabledGetter={isRowDisabled}
								disabledRowExplanationRenderer={renderDisabledRowExplanation}
								headerRenderer={renderHeader}
								items={rows}
								name="items"
								tableHeight={400}
							/>
						)}

						<FormErrorMessages
							errors={{
								segmentsConsistencyViolation: 'Segment is conflicting with an existing segment on target website',
							}}
						/>

						<CenteredFormWrapper>
							<ButtonsLayout>
								<CancelButton />

								<SubmitButton>
									Copy segment to {values.items.length} website(s)
								</SubmitButton>
							</ButtonsLayout>
						</CenteredFormWrapper>
					</>
				)}
			</Form>
		</SimpleModal>
	);
};



export default CopySegmentToWebsitesModal;
