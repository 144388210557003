import React from 'react';

import type CK from '~/types/contentking';

import {
	useDraggable,
} from '@dnd-kit/core';
import SegmentDefinitionIdentifier from '~/components/logic/segments/SegmentDefinitionIdentifier';
import {
	type SegmentDefinition,
} from '~/model/segments';



type Props = {
	disabled?: React.ReactNode,
	name: string,
	onClick?: (segmentName: CK.PageSegmentName) => void,
	segmentDefinition: SegmentDefinition,
};

const DraggableSegmentDefinitionIdentifier: React.FC<Props> = (props) => {
	const {
		disabled = false,
		name,
		onClick,
		segmentDefinition,
	} = props;

	const {
		attributes,
		listeners,
		setNodeRef,
	} = useDraggable({
		id: name,
		disabled: Boolean(disabled),
		data: {
			segmentDefinition,
		},
	});

	return (
		<div
			ref={setNodeRef}
			{...listeners}
			{...attributes}
		>
			<SegmentDefinitionIdentifier
				disabled={Boolean(disabled)}
				disabledExplanation={disabled}
				onClickCallback={onClick}
				segmentDefinition={segmentDefinition}
				showCriteria={false}
				showEditLink={false}
			/>
		</div>
	);
};

export default DraggableSegmentDefinitionIdentifier;
