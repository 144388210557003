import React from 'react';
import {
	defineMessages,
	useIntl,
} from 'react-intl';
import {
	DndContext,
	type DragEndEvent,
	DragOverlay,
	type DragStartEvent,
	MouseSensor,
	TouchSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import {
	restrictToWindowEdges,
	snapCenterToCursor,
} from '@dnd-kit/modifiers';
import {
	List,
} from 'immutable';

import type CK from '~/types/contentking';

import {
	FormContext,
} from '~/components/atoms/forms/basis/Form';
import SegmentDefinitionIdentifier from '~/components/logic/segments/SegmentDefinitionIdentifier';
import {
	OPERATOR_AND,
	OPERATOR_OR,
} from '../SegmentMultiselectField';
import SegmentSelectionSection, {
	SegmentSelectionSectionType,
} from './SegmentSelectionSection';
import SwitchButtonsField, {
	SwitchButtonsFieldSize,
} from '../SwitchButtonsField';

import useFormContext from '~/hooks/useFormContext';

import {
	type SegmentDefinition,
} from '~/model/segments';

import {
	isString,
} from '~/utilities/typeCheck';



const messages = defineMessages({
	operatorAnd: {
		id: 'ui.segments.operation.and',
	},
	operatorOr: {
		id: 'ui.segments.operation.or',
	},
	selectionAddToFilter: {
		id: 'ui.segments.selection.addToFilter',
	},
	selectionIncluded: {
		id: 'ui.segments.selection.included',
	},
	selectionNotIncluded: {
		id: 'ui.segments.selection.notIncluded',
	},
});

enum Selection {
	Included = 'included',
	NotIncluded = 'notIncluded',
	Available = 'available',
}



type Props = {
	includedSegments: Array<string> | List<string>,
	notIncludedSegments: Array<string> | List<string>,
	onChangeCallback: (value: {
		includedSegments: Array<string> | List<string>,
		notIncludedSegments: Array<string> | List<string>,
		operator: string,
	}) => void,
	onOperatorChangeCallback: (value: {
		operator: string,
	}) => void,
	operator: string,
	segments: ReadonlyArray<SegmentDefinition>,
	segmentsNotAllowedForFiltering: Record<string, React.ReactNode> | undefined,
};

const SegmentSelectionArea: React.FC<Props> = (props) => {
	const {
		includedSegments,
		notIncludedSegments,
		onChangeCallback,
		onOperatorChangeCallback,
		operator,
		segments,
		segmentsNotAllowedForFiltering,
	} = props;

	const formContext = useFormContext();
	const intl = useIntl();

	const [draggedSegment, setDraggedSegment] = React.useState<null | SegmentDefinition>(null);

	const mouseSensor = useSensor(MouseSensor, {
		activationConstraint: {
			distance: 3,
		},
	});

	const touchSensor = useSensor(TouchSensor, {
		activationConstraint: {
			distance: 3,
		},
	});

	const sensors = useSensors(mouseSensor, touchSensor);

	const [
		includedSelection,
		notIncludedSelection,
		availableSelection,
	] = React.useMemo(
		() => {
			const includedSelection: Array<SegmentDefinition> = [];
			const notIncludedSelection: Array<SegmentDefinition> = [];
			const availableSelection: Array<SegmentDefinition> = [];

			for (const segment of segments) {
				if (includedSegments.indexOf(segment.name) >= 0) {
					includedSelection.push(segment);
				} else if (notIncludedSegments.indexOf(segment.name) >= 0) {
					notIncludedSelection.push(segment);
				} else {
					availableSelection.push(segment);
				}
			}

			return [
				includedSelection,
				notIncludedSelection,
				availableSelection,
			];
		},
		[
			segments,
			includedSegments,
			notIncludedSegments,
		],
	);

	const updatedFormContext = React.useMemo(
		() => ({
			...formContext,
			defaultValues: {
				operator,
			},
			onChangeHandler: (name, value) => {
				if (name === 'operator') {
					onOperatorChangeCallback({ operator: value });
				}

				return Promise.resolve();
			},
		}),
		[
			formContext,
			onOperatorChangeCallback,
			operator,
		],
	);

	const moveSegment = React.useCallback(
		(segmentName: CK.PageSegmentName, to: Selection) => {
			const nextIncludedSegments = new Set<string>();
			const nextNotIncludedSegments = new Set<string>();

			includedSegments.forEach((segment: string) => nextIncludedSegments.add(segment));
			notIncludedSegments.forEach((segment: string) => nextNotIncludedSegments.add(segment));

			if (to === Selection.Included) {
				nextIncludedSegments.add(segmentName);
				nextNotIncludedSegments.delete(segmentName);
			}

			if (to === Selection.NotIncluded) {
				nextIncludedSegments.delete(segmentName);
				nextNotIncludedSegments.add(segmentName);
			}

			if (to === Selection.Available) {
				nextIncludedSegments.delete(segmentName);
				nextNotIncludedSegments.delete(segmentName);
			}

			// Handle immutable.js List
			if ('get' in includedSegments && 'get' in notIncludedSegments) {
				onChangeCallback({
					includedSegments: List(nextIncludedSegments),
					notIncludedSegments: List(nextNotIncludedSegments),
					operator,
				});
			} else {
				onChangeCallback({
					includedSegments: Array.from(nextIncludedSegments),
					notIncludedSegments: Array.from(nextNotIncludedSegments),
					operator,
				});
			}
		},
		[
			includedSegments,
			notIncludedSegments,
			onChangeCallback,
			operator,
		],
	);

	const handleSegmentClick = React.useCallback(
		(segmentName: CK.PageSegmentName) => {
			moveSegment(segmentName, Selection.Available);
		},
		[
			moveSegment,
		],
	);

	const handleDragStart = React.useCallback(
		(event: DragStartEvent) => {
			setDraggedSegment(event.active.data.current?.segmentDefinition ?? null);
		},
		[],
	);

	const handleDragEnd = React.useCallback(
		(event: DragEndEvent) => {
			if (isString(event.active.id) && isString(event.over?.id)) {
				moveSegment(event.active.id, event.over.id as Selection);
			}

			setDraggedSegment(null);
		},
		[
			moveSegment,
		],
	);

	return (
		<FormContext.Provider value={updatedFormContext}>
			<DndContext
				modifiers={[snapCenterToCursor]}
				onDragEnd={handleDragEnd}
				onDragStart={handleDragStart}
				sensors={sensors}
			>
				<DragOverlay modifiers={[restrictToWindowEdges]}>
					{draggedSegment !== null && (
						<SegmentDefinitionIdentifier
							disabled={true}
							segmentDefinition={draggedSegment}
							showCriteria={false}
							showEditLink={false}
						/>
					)}
				</DragOverlay>
				<div className="segment-selection-area">
					<div className="segment-selection-area__column segment-selection-area__column--drag-target">
						<SegmentSelectionSection
							headerAppendix={(
								<SwitchButtonsField
									items={[
										{
											label: intl.formatMessage(messages.operatorAnd).toUpperCase(),
											value: OPERATOR_AND,
										},
										{
											label: intl.formatMessage(messages.operatorOr).toUpperCase(),
											value: OPERATOR_OR,
										},
									]}
									name="operator"
									size={SwitchButtonsFieldSize.XSmall}
									width={100}
								/>
							)}
							name={Selection.Included}
							onSegmentClick={handleSegmentClick}
							segments={includedSelection}
							segmentsNotAllowedForFiltering={segmentsNotAllowedForFiltering}
							title={intl.formatMessage(messages.selectionIncluded)}
							type={SegmentSelectionSectionType.Add}
						/>

						<SegmentSelectionSection
							name={Selection.NotIncluded}
							onSegmentClick={handleSegmentClick}
							segments={notIncludedSelection}
							segmentsNotAllowedForFiltering={segmentsNotAllowedForFiltering}
							title={intl.formatMessage(messages.selectionNotIncluded)}
							type={SegmentSelectionSectionType.Add}
						/>
					</div>
					<div className="segment-selection-area__column segment-selection-area__column--drag-source">
						<SegmentSelectionSection
							name={Selection.Available}
							segments={availableSelection}
							segmentsNotAllowedForFiltering={segmentsNotAllowedForFiltering}
							title={intl.formatMessage(messages.selectionAddToFilter)}
							type={SegmentSelectionSectionType.Remove}
						/>
					</div>
				</div>
			</DndContext>
		</FormContext.Provider>
	);
};



export default SegmentSelectionArea;
