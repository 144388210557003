import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useDispatch,
} from 'react-redux';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import CalloutMessage, {
	CalloutMessageStatus,
} from '~/components/patterns/messages/embedded/CalloutMessage';
import ButtonsGroup from '~/components/patterns/buttons/ButtonsGroup';
import ColumnsConfiguratorActions from '~/components/patterns/columnsConfigurator/ColumnsConfiguratorActions';
import Copy from '~/components/logic/Copy';
import CreateViewForm from '~/components/atoms/forms/forms/CreateViewForm';
import DepthLayer from '~/components/patterns/utils/DepthLayer';
import SubmitButton, {
	SIZE_SMALL as SUBMIT_BUTTON_SIZE_SMALL,
} from '~/components/atoms/forms/components/SubmitButton';
import WhenAccountActionAllowed from '~/components/app/WhenAccountActionAllowed';

import {
	useCreatePersonalColumnSetMutation,
	useCreateSharedColumnSetMutation,
	useDeleteColumnSetMutation,
	useShareColumnSetMutation,
	useUpdateColumnSetMutation,
} from './ColumnsConfiguratorFooter.gql';

import useChangeColumnSet from '~/hooks/useChangeColumnSet';
import useColumnSets from '~/hooks/useColumnSets';
import useCurrentColumnSet from '~/hooks/useCurrentColumnSet';
import usePersistableCurrentColumns from '~/hooks/usePersistableCurrentColumns';

import {
	MARK_CURRENT_VIEW_AS_DELETED,
	STORE_NEW_VIEW,
	UPDATE_STORED_VIEW,
} from '~/actions/teams/views';



const messages = defineMessages({
	cancelButton: {
		id: 'ui.general.cancelButton',
	},
	createColumnSet: {
		id: 'ui.columnsConfigurator.button.createColumnSet',
	},
	deleteDescription: {
		id: 'ui.columnsConfigurator.deleteForm.body',
	},
	deleteTitle: {
		id: 'ui.columnsConfigurator.deleteForm.title',
	},
	deletionSuccessMessage: {
		id: 'ui.columnsConfigurator.delete.success',
	},
	renameView: {
		id: 'ui.columnsConfigurator.button.rename',
	},
	revertChanges: {
		id: 'ui.columnsConfigurator.button.revert',
	},
	save: {
		id: 'ui.columnsConfigurator.button.save',
	},
	saveAs: {
		id: 'ui.columnsConfigurator.button.saveAs',
	},
	shareNotePersonal: {
		id: 'ui.columnsConfigurator.sharing.bottomMessage.personal',
	},
	shareNoteShared: {
		id: 'ui.columnsConfigurator.sharing.bottomMessage.shared',
	},
	shareView: {
		id: 'ui.columnsConfigurator.button.share',
	},
	saveSuccessMessage: {
		id: 'ui.columnsConfigurator.addForm.success',
	},
	submitDeletion: {
		id: 'ui.columnsConfigurator.deleteForm.submit',
	},
});



enum Status {
	BeforeDelete = 'before-delete',
	BeforeSave = 'before-save',
	Default = 'default',
	Deleted = 'deleted',
	Saved = 'saved',
}



type Props = {
	accountId: CK.AccountId | null,
	isColumnSetModified: boolean,
};

const ColumnsConfiguratorFooter: React.FC<Props> = (props) => {
	const {
		accountId,
		isColumnSetModified,
	} = props;

	const changeColumnSet = useChangeColumnSet();
	const columnSets = useColumnSets();
	const currentColumnSet = useCurrentColumnSet();
	const persistableCurrentColumns = usePersistableCurrentColumns();

	const dispatch = useDispatch();

	const [progress, setProgress] = React.useState(false);
	const [status, setStatus] = React.useState(Status.Default);

	const drawerLockTimeoutRef = React.useRef<ReturnType<typeof setTimeout> | null>(null);

	const [createPersonalColumnSet] = useCreatePersonalColumnSetMutation();
	const [createSharedColumnSet] = useCreateSharedColumnSetMutation();
	const [deleteColumnSet] = useDeleteColumnSetMutation();
	const [shareColumnSet] = useShareColumnSetMutation();
	const [updateColumnSet] = useUpdateColumnSetMutation();

	React.useEffect(
		() => () => {
			if (drawerLockTimeoutRef.current) {
				clearTimeout(drawerLockTimeoutRef.current);
			}
		},
		[
			drawerLockTimeoutRef,
		],
	);

	const showStatus = React.useCallback(
		(status: Status) => {
			setStatus(status);

			drawerLockTimeoutRef.current = setTimeout(
				() => {
					setProgress(false);
					setStatus(Status.Default);
				},
				2000,
			);
		},
		[
			drawerLockTimeoutRef,
			setProgress,
			setStatus,
		],
	);

	const handleDisplayViewDeletion = React.useCallback(
		() => {
			setStatus(Status.BeforeDelete);
		},
		[
			setStatus,
		],
	);

	const handleShareColumnSet = React.useCallback(
		async () => {
			if (accountId === null) {
				throw new Error(
					`accountId can't be null when sharing column set`,
				);
			}

			if (currentColumnSet === null) {
				throw new Error(
					`currentColumnSet can't be null when sharing column set`,
				);
			}

			await shareColumnSet({
				variables: {
					accountId,
					id: currentColumnSet.id,
				},
			});

			dispatch({
				type: UPDATE_STORED_VIEW,
			});
		},
		[
			accountId,
			currentColumnSet,
			dispatch,
			shareColumnSet,
		],
	);

	const handleRevertChanges = React.useCallback(
		(e) => {
			e.preventDefault();

			if (currentColumnSet !== null) {
				changeColumnSet(currentColumnSet.id, false);
			}
		},
		[
			changeColumnSet,
			currentColumnSet,
		],
	);

	const handleDisplaySaveForm = React.useCallback(
		(e) => {
			e.preventDefault();

			setStatus(Status.BeforeSave);
		},
		[
			setStatus,
		],
	);

	const handleUpdateColumnSet = React.useCallback(
		async () => {
			if (currentColumnSet === null) {
				throw new Error(
					`currentColumnSet can't be null when updating column set`,
				);
			}

			setProgress(true);

			await updateColumnSet({
				variables: {
					columns: persistableCurrentColumns,
					id: currentColumnSet.id,
					name: currentColumnSet.name,
				},
			});

			dispatch({
				type: UPDATE_STORED_VIEW,
			});

			showStatus(Status.Saved);
		},
		[
			currentColumnSet,
			dispatch,
			persistableCurrentColumns,
			setProgress,
			showStatus,
			updateColumnSet,
		],
	);

	const handleDeleteColumnSet = React.useCallback(
		async () => {
			if (currentColumnSet === null) {
				throw new Error(
					`currentColumnSet can't be null when deleting column set`,
				);
			}

			await deleteColumnSet({
				variables: {
					id: currentColumnSet.id,
				},
			});

			dispatch({
				type: MARK_CURRENT_VIEW_AS_DELETED,
			});

			showStatus(Status.Deleted);
		},
		[
			currentColumnSet,
			deleteColumnSet,
			dispatch,
			showStatus,
		],
	);

	const handleFormCancel = React.useCallback(
		(e) => {
			if (e) {
				e.preventDefault();
			}

			setStatus(Status.Default);
		},
		[
			setStatus,
		],
	);

	const handleCreateColumnSet = React.useCallback(
		async (model: {
			name: string,
			shareable: boolean,
		}) => {
			if (accountId === null) {
				throw new Error(
					`accountId can't be null when creating column set`,
				);
			}

			setProgress(true);

			if (!model.shareable) {
				model.shareable = false;
			}

			if (model.shareable) {
				const { data } = await createSharedColumnSet({
					variables: {
						accountId,
						columns: persistableCurrentColumns,
						name: model.name,
					},
				});

				if (data?.CreateSharedColumnSet !== undefined) {
					dispatch({
						type: STORE_NEW_VIEW,
						columnSetId: data.CreateSharedColumnSet.createdColumnSet.id,
					});
				}
			} else {
				const { data } = await createPersonalColumnSet({
					variables: {
						columns: persistableCurrentColumns,
						name: model.name,
					},
				});

				if (data?.CreatePersonalColumnSet !== undefined) {
					dispatch({
						type: STORE_NEW_VIEW,
						columnSetId: data.CreatePersonalColumnSet.createdColumnSet.id,
					});
				}
			}

			showStatus(Status.Saved);
		},
		[
			accountId,
			createPersonalColumnSet,
			createSharedColumnSet,
			dispatch,
			persistableCurrentColumns,
			setProgress,
			showStatus,
		],
	);

	function renderActionButtons(currentColumnSet: {
		sharedWithAccountId: CK.AccountId | null,
	} | null) {
		return (
			<ColumnsConfiguratorActions
				buttons={(
					<ButtonsGroup>
						<Button
							onClick={handleDisplaySaveForm}
							size={ButtonSize.Small}
							style={ButtonStyle.Hollow}
						>
							<FormattedMessage {...messages.saveAs} />
						</Button>

						{currentColumnSet !== null && (
							<WhenAccountActionAllowed
								accountId={accountId}
								action={GraphQL.ActionWithAccount.ManageSharedColumnSets}
							>
								{({ isAllowed }) => (
									<SubmitButton
										disabled={currentColumnSet.sharedWithAccountId !== null && isAllowed.yes === false}
										onClickCallback={handleUpdateColumnSet}
										progress={progress}
										size={SUBMIT_BUTTON_SIZE_SMALL}
									>
										<FormattedMessage {...messages.save} />
									</SubmitButton>
								)}
							</WhenAccountActionAllowed>
						)}
					</ButtonsGroup>
				)}
				revertChangesElement={currentColumnSet && (
					<Button
						onClick={handleRevertChanges}
						style={ButtonStyle.Link}
					>
						<FormattedMessage {...messages.revertChanges} />
					</Button>
				)}
			/>
		);
	}

	function renderSaveForm() {
		return (
			<CreateViewForm
				cancelCallback={handleFormCancel}
				submitCallback={handleCreateColumnSet}
			/>
		);
	}

	function renderSavedStatus() {
		return (
			<CalloutMessage
				borders={true}
				centeredContent={true}
				message={(<FormattedMessage {...messages.saveSuccessMessage} />)}
				status={CalloutMessageStatus.Success}
			/>
		);
	}

	function renderViewSharing() {
		return (
			<ColumnsConfiguratorActions
				deletionActionElements={(
					<ButtonsGroup>
						{accountId !== null && (
							<WhenAccountActionAllowed
								accountId={accountId}
								action={GraphQL.ActionWithAccount.ManageSharedColumnSets}
							>
								{({ isAllowed }) => (
									<Button
										disabled={isAllowed.yes === false}
										icon={(
											<BasicIcon type={BasicIconType.Team} />
										)}
										onClick={handleShareColumnSet}
										size={ButtonSize.Small}
										style={ButtonStyle.Hollow}
									>
										<FormattedMessage {...messages.shareView} />
									</Button>
								)}
							</WhenAccountActionAllowed>
						)}
						{/*showRename && (
							<Button
								size={ButtonSize.Small}
								style={ButtonStyle.Hollow}
							>
								<FormattedMessage {...messages.renameView} />
							</Button>
						)*/}
					</ButtonsGroup>
				)}
				deletionButton={(
					<Button
						icon={(
							<BasicIcon type={BasicIconType.Delete} />
						)}
						onClick={handleDisplayViewDeletion}
						size={ButtonSize.Small}
						style={ButtonStyle.Hollow}
					/>
				)}
				note={(
					<Copy {...messages.shareNotePersonal} />
				)}
			/>
		);
	}

	function renderSharedInfo(currentColumnSet: {
		sharedWithAccountId: CK.AccountId | null,
	}) {
		return (
			<ColumnsConfiguratorActions
				deletionButton={accountId !== null && (
					<WhenAccountActionAllowed
						accountId={accountId}
						action={GraphQL.ActionWithAccount.ManageSharedColumnSets}
					>
						{({ isAllowed }) => (
							<Button
								disabled={currentColumnSet.sharedWithAccountId !== null && isAllowed.yes === false}
								icon={(
									<BasicIcon type={BasicIconType.Delete} />
								)}
								onClick={handleDisplayViewDeletion}
								size={ButtonSize.Small}
								style={ButtonStyle.Hollow}
							/>
						)}
					</WhenAccountActionAllowed>
				)}
				deletionNote={(
					<Copy {...messages.shareNoteShared} />
				)}
			/>
		);
	}

	function renderDeleteForm() {
		if (!currentColumnSet) {
			return null;
		}

		return (
			<ColumnsConfiguratorActions
				buttons={(
					<ButtonsGroup>
						<Button
							onClick={handleFormCancel}
							style={ButtonStyle.Link}
						>
							<FormattedMessage {...messages.cancelButton} />
						</Button>

						<Button
							icon={(
								<BasicIcon type={BasicIconType.Delete} />
							)}
							onClick={handleDeleteColumnSet}
							size={ButtonSize.Small}
							style={ButtonStyle.Hollow}
						>
							<FormattedMessage {...messages.submitDeletion} />
						</Button>
					</ButtonsGroup>
				)}
				note={currentColumnSet.sharedWithAccountId !== null && (
					<FormattedMessage {...messages.deleteDescription} />
				)}
				title={(
					<FormattedMessage {...messages.deleteTitle} />
				)}
			/>
		);
	}

	function renderDeletedStatus() {
		return (
			<CalloutMessage
				borders={true}
				centeredContent={true}
				message={(<FormattedMessage {...messages.deletionSuccessMessage} />)}
				status={CalloutMessageStatus.Success}
			/>
		);
	}

	function renderColumnSetBanner(currentColumnSet: {
		sharedWithAccountId: CK.AccountId | null,
	}) {
		if (isColumnSetModified) {
			return renderActionButtons(currentColumnSet);
		} else if (currentColumnSet.sharedWithAccountId === null) {
			return renderViewSharing();
		}

		return renderSharedInfo(currentColumnSet);
	}

	function renderNoColumnSetBanner() {
		return renderActionButtons(null);
	}

	if (columnSets.isLoaded === false) {
		return null;
	}

	return (
		<DepthLayer depthJump={3000}>
			{status === Status.Default && (currentColumnSet !== null ? renderColumnSetBanner(currentColumnSet) : renderNoColumnSetBanner())}

			{status === Status.BeforeSave && renderSaveForm()}
			{status === Status.Saved && renderSavedStatus()}
			{status === Status.BeforeDelete && renderDeleteForm()}
			{status === Status.Deleted && renderDeletedStatus()}
		</DepthLayer>
	);
};



export default ColumnsConfiguratorFooter;
