import {
	List,
	Map,
} from 'immutable';
import _isArray from 'lodash/isArray';
import _isFunction from 'lodash/isFunction';



export function isMap<K = any, V = any>(value: any): value is Map<K, V> {
	return value instanceof Map;
}

export function isList<V = any>(value: any): value is List<V> {
	return value instanceof List;
}

export function isNumber(value: any): value is number {
	return typeof value === 'number';
}

export function isInteger(value: any): value is number {
	return isNumber(value) && Number.isInteger(value);
}

export function isNumerical(value: any): boolean {
	return !isNaN(value);
}

type FunctionLike = ((...rest) => any);
export function isFunction<T = FunctionLike>(value: any): value is T {
	return _isFunction(value);
}

export function isArray<T = any>(value: any): value is Array<T> {
	return _isArray(value);
}

export function isString(value: any): value is string {
	return typeof value === 'string';
}

export function isNonEmptyString(value: any): value is string {
	return isString(value) && value.trim().length > 0;
}

export function isDate(value: any): value is Date {
	return value instanceof Date;
}

export function notEmpty<T = any>(value: T | null | undefined): value is T {
	return value !== null && value !== undefined;
}

export function isNotFalse<T = any>(value: T | false): value is T {
	return value !== false;
}

export function assertString(value: any): asserts value is string {
	if (!isString(value)) {
		throw new Error('Value is not a string');
	}
}

export function assertProperty(object: any, property: string): asserts object is Record<string, any> {
	if (!(property in object)) {
		// eslint-disable-next-line no-console
		console.error('missing property', property, object);
		throw new Error(`Property "${property}" is missing`);
	}
}
